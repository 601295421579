@import url(https://fonts.googleapis.com/css?family=Hind+Vadodara:400,600,700|Frank+Ruhl+Libre:700&display=swap);
body {
	font-family: "Hind Vadodara", sans-serif;
	width: 100wh;
	height: 90vh;
	color: #fff;
	background: -webkit-linear-gradient(135deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	-webkit-animation: Gradient 15s ease infinite;
	animation: Gradient 15s ease infinite;
}

@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}

header {
	text-align: center;
	position: absolute;
	top: 40vh;
	right: 0;
	left: 0;
}

h1 {
	font-family: "Hind Vadodara", sans-serif;
	font-weight: 600;
	font-size: 6vw;
}

span.wave {
	-webkit-animation-name: wave-animation;
	        animation-name: wave-animation;
	-webkit-animation-duration: 2.5s;
	        animation-duration: 2.5s;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	-webkit-transform-origin: 70% 70%;
	        transform-origin: 70% 70%;
	display: inline-block;
}

@-webkit-keyframes wave-animation {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	10% {
		-webkit-transform: rotate(-10deg);
		        transform: rotate(-10deg);
	}
	20% {
		-webkit-transform: rotate(12deg);
		        transform: rotate(12deg);
	}
	30% {
		-webkit-transform: rotate(-10deg);
		        transform: rotate(-10deg);
	}
	40% {
		-webkit-transform: rotate(9deg);
		        transform: rotate(9deg);
	}
	50% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
}

@keyframes wave-animation {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	10% {
		-webkit-transform: rotate(-10deg);
		        transform: rotate(-10deg);
	}
	20% {
		-webkit-transform: rotate(12deg);
		        transform: rotate(12deg);
	}
	30% {
		-webkit-transform: rotate(-10deg);
		        transform: rotate(-10deg);
	}
	40% {
		-webkit-transform: rotate(9deg);
		        transform: rotate(9deg);
	}
	50% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
}

